import { default as aboutNOsRcepLOxMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventse1BNul8tHXMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93rndADHJtq4Meta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sample0YlJ9tnWsKMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45products7TNY7QIsgiMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_936AEG8lIJK3Meta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewPlqadRtP9YMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexQspHAffGPYMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93kD8POWTKADMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexeBFpizbMjAMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93uMrv2EXNxDMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexIt211nTG0lMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsjkHcegtL23Meta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsUf45T436IPMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviews7hHZlcKc3dMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_930zH0gtXYiJMeta } from "/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexBSTns4lRENMeta } from "/tmp/build_40a712de/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmeamJInGL31Meta } from "/tmp/build_40a712de/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmNZkg7xhdY4Meta } from "/tmp/build_40a712de/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendary71vUkwSEPMeta } from "/tmp/build_40a712de/pages/[studio_url]/calendar.vue?macro=true";
import { default as chatz40SMjPnlOMeta } from "/tmp/build_40a712de/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutQAmuy8IH5SMeta } from "/tmp/build_40a712de/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedKaq4ojOgACMeta } from "/tmp/build_40a712de/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersdgvYQANjkiMeta } from "/tmp/build_40a712de/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93gtK3wqRIw3Meta } from "/tmp/build_40a712de/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45editMTlQ1kHpHyMeta } from "/tmp/build_40a712de/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93fKI4xfya2aMeta } from "/tmp/build_40a712de/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as community7QDA53HTxDMeta } from "/tmp/build_40a712de/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93hOwscdNel7Meta } from "/tmp/build_40a712de/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93w1ner4Mjk5Meta } from "/tmp/build_40a712de/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardfEBFyGx7ndMeta } from "/tmp/build_40a712de/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexF53OfdH72YMeta } from "/tmp/build_40a712de/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutDX4OsDlWHfMeta } from "/tmp/build_40a712de/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productPxO285VXQkMeta } from "/tmp/build_40a712de/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsC5Pi6TRabDMeta } from "/tmp/build_40a712de/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93o7ksspGCPyMeta } from "/tmp/build_40a712de/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexaTJfW5AdvNMeta } from "/tmp/build_40a712de/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93BMvIyK0MdYMeta } from "/tmp/build_40a712de/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_932Yvi39O964Meta } from "/tmp/build_40a712de/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexvRSvFMgHvHMeta } from "/tmp/build_40a712de/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93Sw2dWNPjEnMeta } from "/tmp/build_40a712de/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joiny2H8hO9l09Meta } from "/tmp/build_40a712de/pages/[studio_url]/join.vue?macro=true";
import { default as loginCfMwj0MK3cMeta } from "/tmp/build_40a712de/pages/[studio_url]/login.vue?macro=true";
import { default as logoutgCL8ysEdv3Meta } from "/tmp/build_40a712de/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93qHEW66YN5EMeta } from "/tmp/build_40a712de/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93yGbXNxPDaPMeta } from "/tmp/build_40a712de/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_93CbYtP1t4tfMeta } from "/tmp/build_40a712de/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesbz9ZkQfeusMeta } from "/tmp/build_40a712de/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosI7kCElhkF0Meta } from "/tmp/build_40a712de/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinGNbWiLfxw4Meta } from "/tmp/build_40a712de/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_93mo2YeioyoIMeta } from "/tmp/build_40a712de/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as index5xDXtkutLPMeta } from "/tmp/build_40a712de/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indextZVa040HWeMeta } from "/tmp/build_40a712de/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutvfYgOWN56hMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as digital_45download_45contentHN3bBzqWhJMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/digital-download-content.vue?macro=true";
import { default as eventsPnXzuQU3MgMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93YmZdRdY4xkMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleNvqgCrf3J4Meta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsLoDrZOYrXlMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93Q7m1WNaCc3Meta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewT3c1E6hWLOMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexe4epYixSZZMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93icGX5pHAh1Meta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexJ9lOnsexW6Meta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93xvnSxx6pAnMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as index5jfyzTmjcmMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsyTxn3JW1hFMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsyWyEmJuiyqMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsgV92u1dq2KMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93OuEXLpQcTtMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexd9mnSufOHZMeta } from "/tmp/build_40a712de/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexjq8ZwoQmKYMeta } from "/tmp/build_40a712de/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesW6EAz0s97WMeta } from "/tmp/build_40a712de/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsB9ffcZmie0Meta } from "/tmp/build_40a712de/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93cfnYlA8RfAMeta } from "/tmp/build_40a712de/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_930oYRs4GJxGMeta } from "/tmp/build_40a712de/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resettDM3tCoXQyMeta } from "/tmp/build_40a712de/pages/[studio_url]/reset.vue?macro=true";
import { default as shop57LVV1ukJ1Meta } from "/tmp/build_40a712de/pages/[studio_url]/shop.vue?macro=true";
import { default as signupaqO3WYuGfeMeta } from "/tmp/build_40a712de/pages/[studio_url]/signup.vue?macro=true";
import { default as liveKDorgHP2EQMeta } from "/tmp/build_40a712de/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsSV42XwVyTJMeta } from "/tmp/build_40a712de/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93xjKnKqZ7gAMeta } from "/tmp/build_40a712de/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compacthzcp1s6atdMeta } from "/tmp/build_40a712de/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fullGWBJz2BGe2Meta } from "/tmp/build_40a712de/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_930zH0gtXYiJMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_930zH0gtXYiJMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_930zH0gtXYiJMeta || {},
    alias: _91product_id_930zH0gtXYiJMeta?.alias || [],
    redirect: _91product_id_930zH0gtXYiJMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutNOsRcepLOxMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutNOsRcepLOxMeta?.path ?? "about",
    meta: aboutNOsRcepLOxMeta || {},
    alias: aboutNOsRcepLOxMeta?.alias || [],
    redirect: aboutNOsRcepLOxMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventse1BNul8tHXMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventse1BNul8tHXMeta?.path ?? "events",
    meta: eventse1BNul8tHXMeta || {},
    alias: eventse1BNul8tHXMeta?.alias || [],
    redirect: eventse1BNul8tHXMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93rndADHJtq4Meta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93rndADHJtq4Meta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93rndADHJtq4Meta || {},
    alias: _91media_id_93rndADHJtq4Meta?.alias || [],
    redirect: _91media_id_93rndADHJtq4Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sample0YlJ9tnWsKMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sample0YlJ9tnWsKMeta?.path ?? "free-sample",
    meta: free_45sample0YlJ9tnWsKMeta || {},
    alias: free_45sample0YlJ9tnWsKMeta?.alias || [],
    redirect: free_45sample0YlJ9tnWsKMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45products7TNY7QIsgiMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45products7TNY7QIsgiMeta?.path ?? "included-products",
    meta: included_45products7TNY7QIsgiMeta || {},
    alias: included_45products7TNY7QIsgiMeta?.alias || [],
    redirect: included_45products7TNY7QIsgiMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_936AEG8lIJK3Meta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_936AEG8lIJK3Meta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_936AEG8lIJK3Meta || {},
    alias: _91media_id_936AEG8lIJK3Meta?.alias || [],
    redirect: _91media_id_936AEG8lIJK3Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewPlqadRtP9YMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: viewPlqadRtP9YMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewPlqadRtP9YMeta || {},
    alias: viewPlqadRtP9YMeta?.alias || [],
    redirect: viewPlqadRtP9YMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexQspHAffGPYMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexQspHAffGPYMeta?.path ?? "lessons",
    meta: indexQspHAffGPYMeta || {},
    alias: indexQspHAffGPYMeta?.alias || [],
    redirect: indexQspHAffGPYMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93kD8POWTKADMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93kD8POWTKADMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93kD8POWTKADMeta || {},
    alias: _91media_id_93kD8POWTKADMeta?.alias || [],
    redirect: _91media_id_93kD8POWTKADMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexeBFpizbMjAMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexeBFpizbMjAMeta?.path ?? "playlists/:playlist_id()",
    meta: indexeBFpizbMjAMeta || {},
    alias: indexeBFpizbMjAMeta?.alias || [],
    redirect: indexeBFpizbMjAMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93uMrv2EXNxDMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93uMrv2EXNxDMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93uMrv2EXNxDMeta || {},
    alias: _91media_id_93uMrv2EXNxDMeta?.alias || [],
    redirect: _91media_id_93uMrv2EXNxDMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexIt211nTG0lMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexIt211nTG0lMeta?.path ?? "playlists",
    meta: indexIt211nTG0lMeta || {},
    alias: indexIt211nTG0lMeta?.alias || [],
    redirect: indexIt211nTG0lMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsjkHcegtL23Meta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsjkHcegtL23Meta?.path ?? "private-sessions",
    meta: private_45sessionsjkHcegtL23Meta || {},
    alias: private_45sessionsjkHcegtL23Meta?.alias || [],
    redirect: private_45sessionsjkHcegtL23Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsUf45T436IPMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordingsUf45T436IPMeta?.path ?? "recordings",
    meta: recordingsUf45T436IPMeta || {},
    alias: recordingsUf45T436IPMeta?.alias || [],
    redirect: recordingsUf45T436IPMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviews7hHZlcKc3dMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviews7hHZlcKc3dMeta?.path ?? "reviews",
    meta: reviews7hHZlcKc3dMeta || {},
    alias: reviews7hHZlcKc3dMeta?.alias || [],
    redirect: reviews7hHZlcKc3dMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexBSTns4lRENMeta?.name ?? "buy-product-product_id",
    path: indexBSTns4lRENMeta?.path ?? "/buy/product/:product_id()",
    meta: indexBSTns4lRENMeta || {},
    alias: indexBSTns4lRENMeta?.alias || [],
    redirect: indexBSTns4lRENMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmeamJInGL31Meta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmeamJInGL31Meta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmeamJInGL31Meta || {},
    alias: confirmeamJInGL31Meta?.alias || [],
    redirect: confirmeamJInGL31Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmNZkg7xhdY4Meta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmNZkg7xhdY4Meta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmNZkg7xhdY4Meta || {},
    alias: confirmNZkg7xhdY4Meta?.alias || [],
    redirect: confirmNZkg7xhdY4Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendary71vUkwSEPMeta?.name ?? "calendar",
    path: calendary71vUkwSEPMeta?.path ?? "/calendar",
    meta: calendary71vUkwSEPMeta || {},
    alias: calendary71vUkwSEPMeta?.alias || [],
    redirect: calendary71vUkwSEPMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatz40SMjPnlOMeta?.name ?? "chat",
    path: chatz40SMjPnlOMeta?.path ?? "/chat",
    meta: chatz40SMjPnlOMeta || {},
    alias: chatz40SMjPnlOMeta?.alias || [],
    redirect: chatz40SMjPnlOMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93gtK3wqRIw3Meta?.name ?? "community-groups-group_id",
    path: _91group_id_93gtK3wqRIw3Meta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93gtK3wqRIw3Meta || {},
    alias: _91group_id_93gtK3wqRIw3Meta?.alias || [],
    redirect: _91group_id_93gtK3wqRIw3Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutQAmuy8IH5SMeta?.name ?? "community-groups-group_id-about",
    path: aboutQAmuy8IH5SMeta?.path ?? "about",
    meta: aboutQAmuy8IH5SMeta || {},
    alias: aboutQAmuy8IH5SMeta?.alias || [],
    redirect: aboutQAmuy8IH5SMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedKaq4ojOgACMeta?.name ?? "community-groups-group_id-feed",
    path: feedKaq4ojOgACMeta?.path ?? "feed",
    meta: feedKaq4ojOgACMeta || {},
    alias: feedKaq4ojOgACMeta?.alias || [],
    redirect: feedKaq4ojOgACMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersdgvYQANjkiMeta?.name ?? "community-groups-group_id-members",
    path: membersdgvYQANjkiMeta?.path ?? "members",
    meta: membersdgvYQANjkiMeta || {},
    alias: membersdgvYQANjkiMeta?.alias || [],
    redirect: membersdgvYQANjkiMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45editMTlQ1kHpHyMeta?.name ?? "community-profile-edit",
    path: community_45profile_45editMTlQ1kHpHyMeta?.path ?? "/community-profile-edit",
    meta: community_45profile_45editMTlQ1kHpHyMeta || {},
    alias: community_45profile_45editMTlQ1kHpHyMeta?.alias || [],
    redirect: community_45profile_45editMTlQ1kHpHyMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fKI4xfya2aMeta?.name ?? "community-profile-id",
    path: _91id_93fKI4xfya2aMeta?.path ?? "/community-profile/:id()",
    meta: _91id_93fKI4xfya2aMeta || {},
    alias: _91id_93fKI4xfya2aMeta?.alias || [],
    redirect: _91id_93fKI4xfya2aMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: community7QDA53HTxDMeta?.name ?? "community",
    path: community7QDA53HTxDMeta?.path ?? "/community",
    meta: community7QDA53HTxDMeta || {},
    alias: community7QDA53HTxDMeta?.alias || [],
    redirect: community7QDA53HTxDMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93hOwscdNel7Meta?.name ?? "complete-registration-uuid",
    path: _91uuid_93hOwscdNel7Meta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93hOwscdNel7Meta || {},
    alias: _91uuid_93hOwscdNel7Meta?.alias || [],
    redirect: _91uuid_93hOwscdNel7Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93w1ner4Mjk5Meta?.name ?? "contracts-unique_id",
    path: _91unique_id_93w1ner4Mjk5Meta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93w1ner4Mjk5Meta || {},
    alias: _91unique_id_93w1ner4Mjk5Meta?.alias || [],
    redirect: _91unique_id_93w1ner4Mjk5Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardfEBFyGx7ndMeta?.name ?? "dashboard",
    path: dashboardfEBFyGx7ndMeta?.path ?? "/dashboard",
    meta: dashboardfEBFyGx7ndMeta || {},
    alias: dashboardfEBFyGx7ndMeta?.alias || [],
    redirect: dashboardfEBFyGx7ndMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexF53OfdH72YMeta?.name ?? "event-details-event_id",
    path: indexF53OfdH72YMeta?.path ?? "/event/details/:event_id()",
    meta: indexF53OfdH72YMeta || {},
    alias: indexF53OfdH72YMeta?.alias || [],
    redirect: indexF53OfdH72YMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutDX4OsDlWHfMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutDX4OsDlWHfMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutDX4OsDlWHfMeta || {},
    alias: checkoutDX4OsDlWHfMeta?.alias || [],
    redirect: checkoutDX4OsDlWHfMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productPxO285VXQkMeta?.name ?? "event-details-event_id-select-product",
    path: select_45productPxO285VXQkMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productPxO285VXQkMeta || {},
    alias: select_45productPxO285VXQkMeta?.alias || [],
    redirect: select_45productPxO285VXQkMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsC5Pi6TRabDMeta?.name ?? "events",
    path: eventsC5Pi6TRabDMeta?.path ?? "/events",
    meta: eventsC5Pi6TRabDMeta || {},
    alias: eventsC5Pi6TRabDMeta?.alias || [],
    redirect: eventsC5Pi6TRabDMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93o7ksspGCPyMeta?.name ?? "gifts-uuid",
    path: _91uuid_93o7ksspGCPyMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93o7ksspGCPyMeta || {},
    alias: _91uuid_93o7ksspGCPyMeta?.alias || [],
    redirect: _91uuid_93o7ksspGCPyMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexaTJfW5AdvNMeta?.name ?? "gifts",
    path: indexaTJfW5AdvNMeta?.path ?? "/gifts",
    meta: indexaTJfW5AdvNMeta || {},
    alias: indexaTJfW5AdvNMeta?.alias || [],
    redirect: indexaTJfW5AdvNMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93BMvIyK0MdYMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93BMvIyK0MdYMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93BMvIyK0MdYMeta || {},
    alias: _91invitation_id_93BMvIyK0MdYMeta?.alias || [],
    redirect: _91invitation_id_93BMvIyK0MdYMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_932Yvi39O964Meta?.name ?? "gifts-product-product_id",
    path: _91product_id_932Yvi39O964Meta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_932Yvi39O964Meta || {},
    alias: _91product_id_932Yvi39O964Meta?.alias || [],
    redirect: _91product_id_932Yvi39O964Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexvRSvFMgHvHMeta?.name ?? "index",
    path: indexvRSvFMgHvHMeta?.path ?? "/",
    meta: indexvRSvFMgHvHMeta || {},
    alias: indexvRSvFMgHvHMeta?.alias || [],
    redirect: indexvRSvFMgHvHMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93Sw2dWNPjEnMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93Sw2dWNPjEnMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93Sw2dWNPjEnMeta || {},
    alias: _91invitation_unique_id_93Sw2dWNPjEnMeta?.alias || [],
    redirect: _91invitation_unique_id_93Sw2dWNPjEnMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joiny2H8hO9l09Meta?.name ?? "join",
    path: joiny2H8hO9l09Meta?.path ?? "/join",
    meta: joiny2H8hO9l09Meta || {},
    alias: joiny2H8hO9l09Meta?.alias || [],
    redirect: joiny2H8hO9l09Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: loginCfMwj0MK3cMeta?.name ?? "login",
    path: loginCfMwj0MK3cMeta?.path ?? "/login",
    meta: loginCfMwj0MK3cMeta || {},
    alias: loginCfMwj0MK3cMeta?.alias || [],
    redirect: loginCfMwj0MK3cMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutgCL8ysEdv3Meta?.name ?? "logout",
    path: logoutgCL8ysEdv3Meta?.path ?? "/logout",
    meta: logoutgCL8ysEdv3Meta || {},
    alias: logoutgCL8ysEdv3Meta?.alias || [],
    redirect: logoutgCL8ysEdv3Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93qHEW66YN5EMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93qHEW66YN5EMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93qHEW66YN5EMeta || {},
    alias: _91meeting_type_slug_93qHEW66YN5EMeta?.alias || [],
    redirect: _91meeting_type_slug_93qHEW66YN5EMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93yGbXNxPDaPMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93yGbXNxPDaPMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93yGbXNxPDaPMeta || {},
    alias: _91meeting_unique_id_93yGbXNxPDaPMeta?.alias || [],
    redirect: _91meeting_unique_id_93yGbXNxPDaPMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93CbYtP1t4tfMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_93CbYtP1t4tfMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_93CbYtP1t4tfMeta || {},
    alias: _91meeting_unique_id_93CbYtP1t4tfMeta?.alias || [],
    redirect: _91meeting_unique_id_93CbYtP1t4tfMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesbz9ZkQfeusMeta?.name ?? "my-favorites",
    path: my_45favoritesbz9ZkQfeusMeta?.path ?? "/my-favorites",
    meta: my_45favoritesbz9ZkQfeusMeta || {},
    alias: my_45favoritesbz9ZkQfeusMeta?.alias || [],
    redirect: my_45favoritesbz9ZkQfeusMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosI7kCElhkF0Meta?.name ?? "my-studios",
    path: my_45studiosI7kCElhkF0Meta?.path ?? "/my-studios",
    meta: my_45studiosI7kCElhkF0Meta || {},
    alias: my_45studiosI7kCElhkF0Meta?.alias || [],
    redirect: my_45studiosI7kCElhkF0Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinGNbWiLfxw4Meta?.name ?? "optin",
    path: optinGNbWiLfxw4Meta?.path ?? "/optin",
    meta: optinGNbWiLfxw4Meta || {},
    alias: optinGNbWiLfxw4Meta?.alias || [],
    redirect: optinGNbWiLfxw4Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_93mo2YeioyoIMeta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_93mo2YeioyoIMeta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_93mo2YeioyoIMeta || {},
    alias: _91episode_id_93mo2YeioyoIMeta?.alias || [],
    redirect: _91episode_id_93mo2YeioyoIMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: index5xDXtkutLPMeta?.name ?? "podcasts-podcast_id",
    path: index5xDXtkutLPMeta?.path ?? "/podcasts/:podcast_id()",
    meta: index5xDXtkutLPMeta || {},
    alias: index5xDXtkutLPMeta?.alias || [],
    redirect: index5xDXtkutLPMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextZVa040HWeMeta?.name ?? "podcasts",
    path: indextZVa040HWeMeta?.path ?? "/podcasts",
    meta: indextZVa040HWeMeta || {},
    alias: indextZVa040HWeMeta?.alias || [],
    redirect: indextZVa040HWeMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93OuEXLpQcTtMeta?.name ?? "product-product_id",
    path: _91product_id_93OuEXLpQcTtMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93OuEXLpQcTtMeta || {},
    alias: _91product_id_93OuEXLpQcTtMeta?.alias || [],
    redirect: _91product_id_93OuEXLpQcTtMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutvfYgOWN56hMeta?.name ?? "product-product_id-about",
    path: aboutvfYgOWN56hMeta?.path ?? "about",
    meta: aboutvfYgOWN56hMeta || {},
    alias: aboutvfYgOWN56hMeta?.alias || [],
    redirect: aboutvfYgOWN56hMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: digital_45download_45contentHN3bBzqWhJMeta?.name ?? "product-product_id-digital-download-content",
    path: digital_45download_45contentHN3bBzqWhJMeta?.path ?? "digital-download-content",
    meta: digital_45download_45contentHN3bBzqWhJMeta || {},
    alias: digital_45download_45contentHN3bBzqWhJMeta?.alias || [],
    redirect: digital_45download_45contentHN3bBzqWhJMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/digital-download-content.vue").then(m => m.default || m)
  },
  {
    name: eventsPnXzuQU3MgMeta?.name ?? "product-product_id-events",
    path: eventsPnXzuQU3MgMeta?.path ?? "events",
    meta: eventsPnXzuQU3MgMeta || {},
    alias: eventsPnXzuQU3MgMeta?.alias || [],
    redirect: eventsPnXzuQU3MgMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93YmZdRdY4xkMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93YmZdRdY4xkMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93YmZdRdY4xkMeta || {},
    alias: _91media_id_93YmZdRdY4xkMeta?.alias || [],
    redirect: _91media_id_93YmZdRdY4xkMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleNvqgCrf3J4Meta?.name ?? "product-product_id-free-sample",
    path: free_45sampleNvqgCrf3J4Meta?.path ?? "free-sample",
    meta: free_45sampleNvqgCrf3J4Meta || {},
    alias: free_45sampleNvqgCrf3J4Meta?.alias || [],
    redirect: free_45sampleNvqgCrf3J4Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsLoDrZOYrXlMeta?.name ?? "product-product_id-included-products",
    path: included_45productsLoDrZOYrXlMeta?.path ?? "included-products",
    meta: included_45productsLoDrZOYrXlMeta || {},
    alias: included_45productsLoDrZOYrXlMeta?.alias || [],
    redirect: included_45productsLoDrZOYrXlMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93Q7m1WNaCc3Meta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93Q7m1WNaCc3Meta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93Q7m1WNaCc3Meta || {},
    alias: _91media_id_93Q7m1WNaCc3Meta?.alias || [],
    redirect: _91media_id_93Q7m1WNaCc3Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewT3c1E6hWLOMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewT3c1E6hWLOMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewT3c1E6hWLOMeta || {},
    alias: viewT3c1E6hWLOMeta?.alias || [],
    redirect: viewT3c1E6hWLOMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexe4epYixSZZMeta?.name ?? "product-product_id-lessons",
    path: indexe4epYixSZZMeta?.path ?? "lessons",
    meta: indexe4epYixSZZMeta || {},
    alias: indexe4epYixSZZMeta?.alias || [],
    redirect: indexe4epYixSZZMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93icGX5pHAh1Meta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93icGX5pHAh1Meta?.path ?? "media/:media_id()",
    meta: _91media_id_93icGX5pHAh1Meta || {},
    alias: _91media_id_93icGX5pHAh1Meta?.alias || [],
    redirect: _91media_id_93icGX5pHAh1Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexJ9lOnsexW6Meta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexJ9lOnsexW6Meta?.path ?? "playlists/:playlist_id()",
    meta: indexJ9lOnsexW6Meta || {},
    alias: indexJ9lOnsexW6Meta?.alias || [],
    redirect: indexJ9lOnsexW6Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93xvnSxx6pAnMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93xvnSxx6pAnMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93xvnSxx6pAnMeta || {},
    alias: _91media_id_93xvnSxx6pAnMeta?.alias || [],
    redirect: _91media_id_93xvnSxx6pAnMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index5jfyzTmjcmMeta?.name ?? "product-product_id-playlists",
    path: index5jfyzTmjcmMeta?.path ?? "playlists",
    meta: index5jfyzTmjcmMeta || {},
    alias: index5jfyzTmjcmMeta?.alias || [],
    redirect: index5jfyzTmjcmMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsyTxn3JW1hFMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsyTxn3JW1hFMeta?.path ?? "private-sessions",
    meta: private_45sessionsyTxn3JW1hFMeta || {},
    alias: private_45sessionsyTxn3JW1hFMeta?.alias || [],
    redirect: private_45sessionsyTxn3JW1hFMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsyWyEmJuiyqMeta?.name ?? "product-product_id-recordings",
    path: recordingsyWyEmJuiyqMeta?.path ?? "recordings",
    meta: recordingsyWyEmJuiyqMeta || {},
    alias: recordingsyWyEmJuiyqMeta?.alias || [],
    redirect: recordingsyWyEmJuiyqMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsgV92u1dq2KMeta?.name ?? "product-product_id-reviews",
    path: reviewsgV92u1dq2KMeta?.path ?? "reviews",
    meta: reviewsgV92u1dq2KMeta || {},
    alias: reviewsgV92u1dq2KMeta?.alias || [],
    redirect: reviewsgV92u1dq2KMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexd9mnSufOHZMeta?.name ?? "product",
    path: indexd9mnSufOHZMeta?.path ?? "/product",
    meta: indexd9mnSufOHZMeta || {},
    alias: indexd9mnSufOHZMeta?.alias || [],
    redirect: indexd9mnSufOHZMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexjq8ZwoQmKYMeta?.name ?? "profile",
    path: indexjq8ZwoQmKYMeta?.path ?? "/profile",
    meta: indexjq8ZwoQmKYMeta || {},
    alias: indexjq8ZwoQmKYMeta?.alias || [],
    redirect: indexjq8ZwoQmKYMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesW6EAz0s97WMeta?.name ?? "profile-purchases",
    path: purchasesW6EAz0s97WMeta?.path ?? "/profile/purchases",
    meta: purchasesW6EAz0s97WMeta || {},
    alias: purchasesW6EAz0s97WMeta?.alias || [],
    redirect: purchasesW6EAz0s97WMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsB9ffcZmie0Meta?.name ?? "profile-redemptions",
    path: redemptionsB9ffcZmie0Meta?.path ?? "/profile/redemptions",
    meta: redemptionsB9ffcZmie0Meta || {},
    alias: redemptionsB9ffcZmie0Meta?.alias || [],
    redirect: redemptionsB9ffcZmie0Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93cfnYlA8RfAMeta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93cfnYlA8RfAMeta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93cfnYlA8RfAMeta || {},
    alias: _91quiz_id_93cfnYlA8RfAMeta?.alias || [],
    redirect: _91quiz_id_93cfnYlA8RfAMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_930oYRs4GJxGMeta?.name ?? "reset-password-uid-token",
    path: _91token_930oYRs4GJxGMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_930oYRs4GJxGMeta || {},
    alias: _91token_930oYRs4GJxGMeta?.alias || [],
    redirect: _91token_930oYRs4GJxGMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resettDM3tCoXQyMeta?.name ?? "reset",
    path: resettDM3tCoXQyMeta?.path ?? "/reset",
    meta: resettDM3tCoXQyMeta || {},
    alias: resettDM3tCoXQyMeta?.alias || [],
    redirect: resettDM3tCoXQyMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shop57LVV1ukJ1Meta?.name ?? "shop",
    path: shop57LVV1ukJ1Meta?.path ?? "/shop",
    meta: shop57LVV1ukJ1Meta || {},
    alias: shop57LVV1ukJ1Meta?.alias || [],
    redirect: shop57LVV1ukJ1Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupaqO3WYuGfeMeta?.name ?? "signup",
    path: signupaqO3WYuGfeMeta?.path ?? "/signup",
    meta: signupaqO3WYuGfeMeta || {},
    alias: signupaqO3WYuGfeMeta?.alias || [],
    redirect: signupaqO3WYuGfeMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveKDorgHP2EQMeta?.name ?? "streaming-event_id-live",
    path: liveKDorgHP2EQMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveKDorgHP2EQMeta || {},
    alias: liveKDorgHP2EQMeta?.alias || [],
    redirect: liveKDorgHP2EQMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsSV42XwVyTJMeta?.name ?? "tips",
    path: tipsSV42XwVyTJMeta?.path ?? "/tips",
    meta: tipsSV42XwVyTJMeta || {},
    alias: tipsSV42XwVyTJMeta?.alias || [],
    redirect: tipsSV42XwVyTJMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93xjKnKqZ7gAMeta?.name ?? "waiver-uuid",
    path: _91uuid_93xjKnKqZ7gAMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93xjKnKqZ7gAMeta || {},
    alias: _91uuid_93xjKnKqZ7gAMeta?.alias || [],
    redirect: _91uuid_93xjKnKqZ7gAMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compacthzcp1s6atdMeta?.name ?? "widgets-calendars-compact",
    path: compacthzcp1s6atdMeta?.path ?? "/widgets/calendars/compact",
    meta: compacthzcp1s6atdMeta || {},
    alias: compacthzcp1s6atdMeta?.alias || [],
    redirect: compacthzcp1s6atdMeta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fullGWBJz2BGe2Meta?.name ?? "widgets-calendars-full",
    path: fullGWBJz2BGe2Meta?.path ?? "/widgets/calendars/full",
    meta: fullGWBJz2BGe2Meta || {},
    alias: fullGWBJz2BGe2Meta?.alias || [],
    redirect: fullGWBJz2BGe2Meta?.redirect,
    component: () => import("/tmp/build_40a712de/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]